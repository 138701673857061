<template>
    <v-container fluid>
        <tool-bar :toolbar="toolbar" @toggleSearch="toggleSearch" @toggleBackHistory="toggleBackHistory"></tool-bar>

        <template v-if="!showDetails">
            <v-simple-table fixed-header :height="tableHeight" class="mainTable">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center simple-table=header"> No. </th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.categoryCode") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.trader") }} </th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.estimatedDate") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.orderedDate") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.deliveryDateDefinition") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.estimatedDeliveryDate") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.actions") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in displayOrderHistory" :key="index">
                            <td class="text-center"> {{ item.ORDER_NO }} </td>
                            <td class="text-center"> 
                                <span class="mr-1"> {{ item.HATCHU_GYOSYU_CD }} </span>-
                                <span class="mr-1 ml-1"> {{ item.KANRI_NO }} </span>-
                                <span class="ml-1"> {{ getCategoryName(item.HATCHU_GYOSYU_CD) }} </span>
                            </td>
                            <td class="text-center"> {{ item.TRADER_MEI_1 }} </td>
                            <td class="text-center"> {{ getEstimationOrderedDate(item.HIROI_COMP_DATE) }} </td>
                            <td class="text-center"> {{ getEstimationOrderedDate(item.HKO_DATE) }} </td>
                            <!-- <td></td>
                            <td></td> -->
                            <td class="text-center">
                                <div v-if="item.HATCHU_GYOSYU_CD == '101' && item.regularOrder =='1'">
                                    <h4>現場取付用：１階建方予定日20日前</h4>
                                    <h4>工場プレセット用：１階建方予定日35日前</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '351' && item.regularOrder =='1'">
                                    <h4>土台配送便14日前</h4>
                                    <h4>クロス出荷日</h4>
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '363' && item.regularOrder =='1'">
                                    <h4>1階材配送便</h4>
                                    <h4>クロス出荷日</h4>
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '368' && item.regularOrder =='1'">
                                    <h4>配管部材：１階建方予定日2０日前</h4>
                                    <h4>住設配送設定部材：１階建方予定日２０日後</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '410' && item.regularOrder =='1'">
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '537' && item.regularOrder =='1'">
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '204' && item.regularOrder =='1'">
                                    <h4>基礎着工予定日10日前</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '208' && item.regularOrder =='1'">
                                    <h4>１階建方予定日２５日前</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '209' && item.regularOrder =='1'">
                                    <h4>１階建方予定日</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '305' && item.regularOrder =='1'">
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '306' && item.regularOrder =='1'">
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '327' && item.regularOrder =='1'">
                                    <h4>１階建方予定日</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '333' && item.regularOrder =='1'">
                                    <h4>クロス出荷日</h4>
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '349' && item.regularOrder =='1'">
                                    <h4>床材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '356' && item.regularOrder =='1'">
                                    <h4>オリジナル住設配送便</h4>
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '357' && item.regularOrder =='1'">
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '359' && item.regularOrder =='1'">
                                    <h4>造作配送1便</h4>
                                    <h4>オリジナル住設配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '362' && item.regularOrder =='1'">
                                    <h4>オリジナル住設配送便</h4>
                                    <h4>クロス出荷日</h4>
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '365' && item.regularOrder =='1'">
                                    <h4>クロス出荷日</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '348' && item.regularOrder =='1'">
                                    <h4>1階材配送便</h4>
                                    <h4>造作配送2便</h4>
                                    <h4>オリジナル住設配送便</h4>
                                    <h4>クロス出荷日</h4>
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '554' && item.regularOrder =='1'">
                                    <h4>クロス出荷日</h4>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '541' && item.regularOrder =='1'">
                                    <h4>仕上げ材配送便</h4>
                                </div>
                                <div v-else></div>
                            </td>
                            <td class="text-center">
                                <div v-if="item.HATCHU_GYOSYU_CD == '101'  && item.regularOrder =='1'">
                                    <h3>{{estimatedDeliveryDateBefore(item.JoutouDate, 20)}}</h3>
                                    <h3>{{estimatedDeliveryDateBefore(item.JoutouDate, 35)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '351' && item.regularOrder =='1'">
                                    <h3>{{estimatedDeliveryDateBefore(item.DeliveredFoundationDate, 14)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '363' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFirstFloorMaterialDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '368' && item.regularOrder =='1'">
                                    <h3>{{estimatedDeliveryDateBefore(item.JoutouDate, 20)}}</h3>
                                    <h3>{{estimatedDeliveryDateAfter(item.JoutouDate, 20)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '410' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '537' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '204' && item.regularOrder =='1'">
                                    <h3>{{estimatedDeliveryDateBefore(item.KisoStartDate, 10)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '208' && item.regularOrder =='1'">
                                    <h3>{{estimatedDeliveryDateBefore(item.JoutouDate, 25)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '209' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.JoutouDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '305' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '306' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '327' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.JoutouDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '333' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '349' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFloorMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '356' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredOriginalJyusetsuDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '357' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '359' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFixturesMaterial1Date)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredOriginalJyusetsuDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '362' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredOriginalJyusetsuDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '365' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '348' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFirstFloorMaterialDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFixturesMaterial2Date)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredOriginalJyusetsuDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '554' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredClothDate)}}</h3>
                                </div>
                                <div v-else-if="item.HATCHU_GYOSYU_CD == '541' && item.regularOrder =='1'">
                                    <h3>{{getEstimationOrderedDate(item.DeliveredFinishingMaterialDate)}}</h3>
                                </div>
                                <div v-else></div>
                            </td>
                            <td class="text-center"> 
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn small v-on="on" v-bind="attrs" icon @click="showOrderDetails(item)">
                                            <v-icon class="search-file-svg"></v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View Details</span>
                                </v-tooltip>
                                <!-- TODO ADDITIONAL FUNCTION -->
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="pl-5" small v-on="on" v-bind="attrs" icon @click="showOrderDetailsExtract(item)">
                                            <v-icon color="#1B5E20" class="mdi mdi-file-excel-outline"></v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Extract CSV</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
        <template v-if="showDetails">
            <v-simple-table fixed-header :height="tableHeight" class="mainTable">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.materialCode") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.materialName") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.summary") }}</th>
                            <th class="text-center simple-table=header">{{ $t("orderHistoryPage.quatity") }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(order, orderIndex) in displayOrderDetails" :key="orderIndex">
                            <td class="text-center"> {{ order.BUZAI_CD }} </td>
                            <td class="text-center"> {{ order.BUZAI_MEI }} </td>
                            <td class="text-center"> {{ !order.TEKIYO ? '' : order.TEKIYO }} </td>
                            <td class="text-center"> {{ !order.SURYO.split('.')[0] ? 0 : order.SURYO.split('.')[0]}} {{ order.UNIT }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
        
        <v-row justify-center>
            <v-dialog
                v-model="searchDialog"
                persistent
                width="400"
                @keydown.esc="searchDialog = false;"
            >
                <v-card>
                <v-card-title class="form-card-title">
                    <!-- <v-icon dark class="mr-1" size="20">mdi-magnify</v-icon> -->
                    <span class="span-style">Search Category</span>
                    <v-spacer></v-spacer>
                    <v-icon dark @click="searchDialog = false;">mdi-close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="mt-2">
                            <v-text-field
                                v-model="searchInfo.construction_code"
                                v-mask="constructionMasked"
                                :label="$t('homeHeader.constructionCode')"
                                dense
                                outlined
                                persistent-placeholder
                                autocomplete="off"
                                class="mr-2 ml-2"
                                >
                            </v-text-field>
                            <!-- <v-autocomplete
                                v-model="searchInfo.category_code"
                                :items="industryData"
                                :item-text="item => `( ${item.additional_industry_id} ) ${item.product_industry_name}`"
                                item-value="additional_industry_id"
                                :label="$t('industryHeader.additionalOrderingIndustry')"
                                dense
                                outlined
                                persistent-placeholder
                                autocomplete="off"
                                class="mr-2 ml-2"
                            >
                            </v-autocomplete> -->
                            <!-- REVIEW TO ALL -->
                            <v-autocomplete
                                v-model="searchInfo.category_code"
                                :items="displayOrderAll"
                                :item-text="item => `( ${item.additional_industry_id} ) ${item.product_industry_name}`"
                                item-value="additional_industry_id"
                                :label="$t('industryHeader.additionalOrderingIndustry')"
                                dense
                                outlined
                                persistent-placeholder
                                autocomplete="off"
                                class="mr-2 ml-2"
                            >
                            </v-autocomplete>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="handleSearch()" :disabled="isSearch" class="mt-1 mb-1" color="#0C2D48" style="color: white; height: 28px !important; font-size: 11px;" dense>SEARCH
                        <v-icon dark size="15" class="ml-1">mdi-magnify</v-icon>
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
            </v-row>

            <v-overlay :value="loadMore" overlay>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
                <h4 class="mt-1" style="color: white !important">fetching data...</h4>
            </v-overlay>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
    import Toolbar from '../includes/Toolbar.vue'
    import store from '../../store';
    import swal from 'sweetalert2';
    // import Excel from "exceljs";
    // import { saveAs } from "file-saver";

    export default {
        components: {
            "tool-bar": Toolbar,
        },
        sockets: {
            updateGcTsuikaData: function(socket) {
                // console.log(socket,'Client');
                if(socket.system == 'gc-tsuika' && socket.dispatchType == 'response-local') {
                    if(socket.request == 'getOrderHistory') {
                        if(this.loadMore == true) {
                            const orderData = socket.orderData;

                                this.orderHistory = orderData.data;
                                this.constructionHouseType = orderData.house_type;
                                this.toolbar.houseType = orderData.house_type
                            
                        }
                    }

                    if(socket.request == 'selectedOrderDetails') {
                        if(socket.data.length) {
                            this.loadMore = false;
                            this.orderDetailsData = socket.data;
                        }else{
                            this.showDetails = false;
                            swal.fire({
                                position: "center",
                                width:'500',
                                icon: "error",
                                title: `No Data Found
                                ${this.orderCustomerCode}-(${this.orderCategoryCode})
                                        OrderNo.(${this.orderOrderNo})`,
                                showConfirmButton: true,
                            });
                            this.loadMore = false;
                            this.toolbar.backToOrderHistory = false;
                        }
                    }
                    
                    // if (socket.request == 'getOrgJesutsuDelivery'){
                    //     this.origJusetsu = socket.data
                    //     this.toolbar.orgJusestsuDeliver = this.origJusetsu
                    // }
                    
                }
            }
        },
        data: () => ({
            toolbar: {
                title: 'toolbar.homeTitle',
                sub_title: 'orderHistoryPage.orderHistory',
                orderHistory: true,
                orderCode: "",
                orderCategoryCode: "",
                backToOrderHistory: false,
            },
            searchDialog: true,
            constructionMasked: 'XXXXXXX-XXXX',
            searchInfo: {},
            isSearch: true,
            orderHistory: [],
            orderDetails: [],
            tableHeight: window.innerHeight - 160,
            loadMore: false,
            showDetails: false,
            constructionHouseType: "",
            traderCodes: [],
            orderDetailsData: [],
            origJusetsu:'',
            orderCustomerCode:'',
            orderCategoryCode:'',
            orderOrderNo:'',
        }),
        computed: {
            ...mapState(['industryData']),
            displayOrderHistory() {
                return this.orderHistory
            },
            displayOrderDetails() {
                // console.log(this.orderDetailsData,'orderDetailsData');
                return this.orderDetailsData;
            },
            displayOrderAll() {// REVIEW TO ALL DATA
                // console.log(this.industryData,'test')
                let data = this.industryData;
                data.unshift({id: 0, additional_industry_id: 'All', product_industry_name: "全業種"})
                return data;
            },
        },  
        methods: {
            ...mapActions(['loadCategoryData']),
            searchOrderHistory() {
                this.searchDialog = false;
                this.loadMore = true;

                const email = store.state.loggedInUser.email;
                const gcUser = email.replace(/^[^@]+@/, '');
                // console.log(gcUser, 'gcUser');

                const userRules = [
                { gcIncharge: 'ichijo-sendai.com', plan: 'A' },
                { gcIncharge: 'ichijo-miyagi.co.jp', plan: 'B' },
                { gcIncharge: 'ichijo-c.com', plan: 'C' },
                { gcIncharge: 'ichijo-gunma.com', plan: 'D' },
                { gcIncharge: 'takanohome.jp', plan: 'E' },
                { gcIncharge: 'takanohome.jp', plan: 'F' },
                { gcIncharge: 'shimoden.co.jp', plan: 'I' },
                { gcIncharge: 'ichijo-h.co.jp', plan: 'J' },
                { gcIncharge: 'ichijo-sanin.co.jp', plan: 'K' },
                { gcIncharge: 'ichijo-ku.co.jp', plan: 'L' },
                ];

                const userRule = userRules.find(rule => gcUser.endsWith(rule.gcIncharge));
                if (store.state.loggedInUser.access.length == '12' || store.state.loggedInUser.email == 'nissan-gc@kk-nissan.co.jp' || store.state.loggedInUser.account_type == 'Nihon Sangyou'){
                    this.$socket.emit('gcTsuikaData', {
                        system: 'gc-tsuika',
                        request: 'searchOrderHistory',
                        requestData: this.searchInfo,
                        dispatchType: 'request-local',
                        port: this.windowLoc.port,
                        protocol: this.windowLoc.protocol,
                        hostname: this.windowLoc.hostname,
                    });
                }else if (userRule) {
                    // if (this.searchInfo.construction_code.startsWith(userRule.plan)) {
                        if (this.searchInfo.construction_code.startsWith(userRule.plan) || (userRule.plan === 'E' && this.searchInfo.construction_code.startsWith('F'))) {
                        this.$socket.emit('gcTsuikaData', {
                            system: 'gc-tsuika',
                            request: 'searchOrderHistory',
                            requestData: this.searchInfo,
                            dispatchType: 'request-local',
                            port: this.windowLoc.port,
                            protocol: this.windowLoc.protocol,
                            hostname: this.windowLoc.hostname,
                        });
                    }else {
                        this.loadMore = false;
                        this.searchDialog = true;
                        swal.fire({
                            position: "center",
                            icon: "error",
                            title: `Only your own planned code can be searched.`,
                            showConfirmButton: true,
                        });
                        }
                }

            },
            
            // getOrgJesutsuDelivery() {
            //     this.$socket.emit('gcTsuikaData', {
            //         system: 'gc-tsuika',
            //         request: 'getOrgJesutsuDelivery',
            //         requestData: this.searchInfo.construction_code,
            //         dispatchType: 'request-local',
            //         port: this.windowLoc.port,
            //         protocol: this.windowLoc.protocol,
            //         hostname: this.windowLoc.hostname,
            //     })
            // },
            handleSearch(){
                this.isSearch = true;
                this.showDetails = false;
                this.searchOrderHistory();
                // this.getOrgJesutsuDelivery();
            },
            toggleSearch() {
                this.searchInfo = {};
                this.searchDialog = true;
            },
            getCategoryName(categoryCode) {
                return this.industryData.find(res => res.additional_industry_id == categoryCode)?.product_industry_name;
            },
            getSelectedOrderDetails(selectedOrder){
                this.$socket.emit('gcTsuikaData', {
                    system: 'gc-tsuika',
                    request: 'selectedOrderDetails',
                    requestData: selectedOrder,
                    dispatchType: 'request-local',
                    port: this.windowLoc.port,
                    protocol: this.windowLoc.protocol,
                    hostname: this.windowLoc.hostname,
                })
            },
            showOrderDetails(orderDetails) {
                // console.log(orderDetails,'orderDetails');
                this.orderCustomerCode = orderDetails.CUSTOMER_CODE
                this.orderCategoryCode = orderDetails.HATCHU_GYOSYU_CD
                this.orderOrderNo = orderDetails.ORDER_NO
                this.loadMore = true;
                this.showDetails = true;
                this.toolbar.backToOrderHistory = true;
                
                const selectedOrder = {
                    construction_code: orderDetails.CUSTOMER_CODE,
                    category_code: orderDetails.HATCHU_GYOSYU_CD,
                    category_code_no: orderDetails.KANRI_NO
                }
                this.getSelectedOrderDetails(selectedOrder);

            },

            showOrderDetailsExtract(orderDetails) {
                // console.log(orderDetails,'orderDetails');
                this.loadMore = true;
                this.orderCustomerCode = orderDetails.CUSTOMER_CODE
                this.orderCategoryCode = orderDetails.HATCHU_GYOSYU_CD
                this.orderOrderNo = orderDetails.ORDER_NO
                const selectedOrder = {
                    construction_code: orderDetails.CUSTOMER_CODE,
                    category_code: orderDetails.HATCHU_GYOSYU_CD,
                    category_code_no: orderDetails.KANRI_NO,
                    category_code_order: orderDetails.ORDER_NO
                }
                this.getSelectedOrderDetails(selectedOrder);
                setTimeout(() => {
                        this.extractCsv(selectedOrder);
                }, 2000);
                this.orderDetailsData = []
            },

            toggleBackHistory() {
                this.orderDetailsData = []
                this.showDetails = false;
                this.toolbar.backToOrderHistory = false;
            },
            removeTime(dateTime){
                if(dateTime) {
                    let date = dateTime?.split(" ");
                    return date[0];
                } else {
                    return '';
                }
            },
            getEstimationOrderedDate(item) {
                const date = this.removeTime(item);
                return date
            },
            estimatedDeliveryDateBefore(item,daysToSubtract){
                if(item){
                    var temp = new Date(item);
                    temp.setDate(temp.getDate()- daysToSubtract +1);
                    temp.setHours(0, 0, 0, 0);
                    const formattedDate = temp.toISOString().split('T')[0];
                    // console.log(formattedDate);
                    return formattedDate;
                }
            },
            estimatedDeliveryDateAfter(item,daysToAdd){
                if(item){
                    var temp = new Date(item);
                    temp.setDate(temp.getDate()+ daysToAdd +1);
                    const formattedDate = temp.toISOString().split('T')[0];

                    // console.log(formattedDate);
                    return formattedDate;
                }
            },
            extractCsv(selectedOrder) {
                const filename = `${selectedOrder.construction_code}-(${selectedOrder.category_code})-[${selectedOrder.category_code_order}]`;
                let arr = this.orderDetailsData;

                let csvData = arr.map(extractdata => {
                    return [
                        extractdata.BUZAI_CD,
                        extractdata.BUZAI_MEI,
                        extractdata.TEKIYO,
                        extractdata.SURYO,
                        extractdata.UNIT,
                    ].join(",");
                });

                let csvContent = [
                    "MaterialCode,MaterialName,Summary,Quantity,Unit",
                    ...csvData
                ].join("\r\n");

                // Convert CSV content to Blob with UTF-8 encoding
                const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvContent], { type: "text/csv;charset=UTF-8;" });

                // Create a link to download the CSV file
                const download = document.createElement("a");
                download.href = URL.createObjectURL(blob);
                download.download = filename;

                // Append the link to the document
                document.body.appendChild(download);

                // Trigger a click event to initiate the download
                download.click();

                // Remove the link from the document
                document.body.removeChild(download);
            },
        },//end methods
        watch: {
            searchInfo: {
                handler(val) {
                    if(!val.construction_code || !val.category_code) {
                        this.isSearch = true;
                    } else {
                        this.isSearch = false;
                    }
                },
                deep: true,
            },
            orderHistory: {
                handler(val) {
                const toSearch = this.searchInfo;
                    if(val.length) {
                        this.toolbar.orderCode = toSearch.construction_code
                        this.toolbar.orderCategoryCode = `( ${toSearch.category_code} ) ${this.getCategoryName(toSearch.category_code)}` 
                        this.loadMore = false
                    } else {
                        this.toolbar.orderCode = toSearch.construction_code
                        this.toolbar.orderCategoryCode = `( ${toSearch.category_code} ) ${this.getCategoryName(toSearch.category_code)}` 
                        this.loadMore = false
                    }
                },
                deep: true
            },
        },
        mounted() {
            this.loadCategoryData();
        },
    }
</script>

<!--
    351 - DeliveredFoundationDate - DeliveredClothDate - DeliveredFinishingMaterialDate
    363 - DeliveredFirstFloorMaterialDate - DeliveredClothDate - DeliveredFinishingMaterialDate
    410 - DeliveredFinishingMaterialDate
    537 - DeliveredFinishingMaterialDate
    305 - DeliveredFinishingMaterialDate
    306 - DeliveredFinishingMaterialDate
    333 - DeliveredClothDate - DeliveredFinishingMaterialDate
    349 - DeliveredFloorMaterialDate
    356 - DeliveredOriginalJyusetsuDate - DeliveredFinishingMaterialDate
    357 - DeliveredFinishingMaterialDate
    359 - DeliveredFixturesMaterial1Date - DeliveredOriginalJyusetsuDate
    362 - DeliveredOriginalJyusetsuDate - DeliveredClothDate  - DeliveredFinishingMaterialDate
    365 - DeliveredClothDate
    348 - DeliveredFirstFloorMaterialDate - DeliveredFixturesMaterial2Date  - DeliveredOriginalJyusetsuDate - DeliveredClothDate  - DeliveredFinishingMaterialDate
    554 - DeliveredClothDate
    541 - DeliveredFinishingMaterialDate
-->



<!-- 
    ConstructionCode: お客様コード
    DeliveredMaterialForBaseTraderDate: 基礎業者便
    DeliveredDomadannetsuMaterialDate: 土間断熱便
    DeliveredSeismicIsolationDevicesDate: 免震装置配送便
    DeliveredSeismicIsolationSteelDate: 免震鉄骨配送便
    DeliveredFoundationDate: 土台配送便
    DeliveredIubDate: IUB配送便
    DeliveredFirstFloorMaterialDate: １階材配送便
    DeliveredFirstFloorDayDate: １階当日便
    DeliveredBayWindowDate: ユニット出窓配送便
    DeliveredSecondFloorMaterialDate: ２階材配送便
    DeliveredThirdFloorMaterialDate: ３階材配送便
    DeliveredRoofMaterialDate: 屋根材配送
    DeliveredRoofEndDate: 屋根仕舞便
    DeliveredAfterHouseRaisingMaterialDate: 後材配送便
    DeliveredFloorMaterialDate: 床材配送便
    DeliveredOutsideWallMaterial1Date: 外壁材配送１便
    DeliveredOutsideWallTileDate: 外壁タイル配送便
    DeliveredSidingDate: サイディング配送便
    DeliveredNokitenSidingDate: 軒天サイディング配送便
    DeliveredTileSidingDate: タイル下地サイディング便
    DeliveredTobakoSidingDate: 戸箱サイディング便
    DeliveredTemabariSidingDate: 手間貼サイディング配送便
    DeliveredSashMogolDate: サッシモール配送便
    DeliveredDesignSidingDate: デザインサイディング配送便
    DeliveredOutsideWallMaterial2Date: 外壁材配送２便
    DeliveredFixturesMaterial1Date: 造作配送１便
    DeliveredFixturesMaterial2Date: 造作配送２便
    DeliveredOriginalJyusetsuDate: オリジナル住設配送便
    DeliveredClothDate: クロス出荷日
    DeliveredFinishingMaterialDate: 仕上げ材配送便
    DeliveredLightingMaterialDate: 照明器具配送便
 -->